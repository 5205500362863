//pull in bootstrap
//require('bootstrap');


/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
                /**
                 * Dynamic forward path logic
                 */
                $('.actions .dropdown-item').on('click', function(e) {
                    e.preventDefault();
                    var href = $(this).attr('href');
                    var $parents = $(this).parents();

                    $('#fp-next').attr('href', href);
                    $parents.find('button.dropdown-toggle').text($(this).text());
                });

                /*
                 * Add nav bar under link on hover
                 */
                const triggers = document.querySelectorAll('.nav-link');
                const highlight = document.querySelector('#nav-link-bar');

                //highlight.classList.add('highlight');
                //document.body.append(highlight);

                function highlightlink() {
                    //console.log('test');
                    const linkCoords = this.getBoundingClientRect();

                    const coords = {
                        width: linkCoords.width,
                        height: linkCoords.height,
                        top: linkCoords.bottom + window.scrollY - 4,
                        left: linkCoords.left + window.scrollX,
                    };

                    highlight.style.width = coords.width + 'px';
                    //highlight.style.height = coords.height+'px';
                    highlight.style.transform = 'translate' + '(' + coords.left + 'px' + ',' + coords.top + 'px' + ')';


                    //console.log(linkCoords);
                    //console.log('translate'+'('+coords.left+'px'+','+coords.top+'px'+')');

                }
                triggers.forEach(function(a) {
                    return a.addEventListener('mouseenter', highlightlink);
                });


                //featherlightGallery
                $('.image_gallery a').featherlightGallery({
                    openSpeed: 300,
                    previousIcon: '&#8249;',
                    nextIcon: '&#8250;',
                });


                //GEO USER LOCATION
                getLocation();

                function getLocation() {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(showPosition);
                    } else {
                        console.alert('Geolocation is not supported by this browser');
                    }
                }



                function showPosition(position) {

                    console.log("Your location is: Latitude: " + position.coords.latitude + "Longitude: " + position.coords.longitude);

                    //enter current location var
                    var current = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }

                    //get array of location lan and lng from data type
                    var locations = document.querySelectorAll('.fw-location');
                    var listLocations = [];

                    //create object array from data-set
                    function getLatLng(a) {
                        var groupLocations = {
                                lat: Number(a.dataset.lat),
                                lng: Number(a.dataset.lng)
                            }
                            //var groupLocations = a.dataset.lng;
                        return groupLocations;
                    }

                    locations.forEach(function(a) {
                        listLocations.push(getLatLng(a));
                    });

                    //console.log(listLocations);


                    //list of all coords
                    var coords = listLocations;

                    //calc distance
                    var distFromCurrent = function(coord) {
                        return { coord: coord, dist: geolib.getDistance(current, coord) };
                    }



                    //sort though array of elements find closest, add css order
                    var i = 0;
                    locations.forEach(function(a) {
                        //find closest
                        var closest = coords.map(distFromCurrent).sort(function(a, b) {
                            return a.dist - b.dist })[i];
                        //console.log("Closest: " + closest.coord.lat + ", " + closest.coord.lng);

                        //console.log(a.dataset.lat);
                        //console.log(closest.coord.lat);

                        //add css to div from nearest to farthest
                        $("div[data-lat='" + closest.coord.lat + "']").css("order", i);

                        i++;
                    });

                }
                //end geolocation

            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
